<template>
  <div class="contact-us py-5 mt-5">
    <!-- Main Initial Section -->
    <!-- <main-jumbotron /> -->

    <!-- Contact Us Body Section -->
    <section class="contact-us__body my-5 py-5 ">
      <b-container fluid>
        <article>
          <h1 class="text-center main-heading">
            {{ $t("heading.contact_us") }}
          </h1>
          <p
            class="main-description text-center w-75 mx-auto"
            v-html="contactDescription"
          >
            <!-- {{ contactDescription }} -->
          </p>
        </article>
        <div class="contact-us__illusion my-5 d-none d-lg-block">
          <b-img
            :src="require('@/assets/imgs/illustrations/contactUs/001.jpg')"
            fluid
            class="w-100"
          ></b-img>
        </div>
        <div class="contact-us__form">
          <b-row no-gutters>
            <b-col lg="6">
              <b-container>
                <b-row>
                  <b-col lg="12">
                    <div
                      class="form-box contact-us__form--height"
                      key="submited-form"
                    >
                      <b-form v-if="!isSubmited" @submit.prevent="handleSubmit">
                        <b-row>
                          <b-col md="8" class="mx-auto">
                            <h2 class="sub-heading">
                              {{ $t("heading.contact_us") }}
                            </h2>
                            <form-wrapper :validator="$v.form">
                              <form-group
                                attribute="input.username"
                                name="user_name"
                              >
                                <b-form-input
                                  slot-scope="{ attrs, listeners }"
                                  v-model="form.user_name"
                                  class="form-box__input"
                                  v-bind="attrs"
                                  type="text"
                                  :placeholder="$t('input.username')"
                                  trim
                                  v-on="listeners"
                                />
                              </form-group>
                              <form-group
                                attribute="input.phone"
                                name="user_phone"
                              >
                                <b-form-input
                                  slot-scope="{ attrs, listeners }"
                                  v-model="form.user_phone"
                                  class="form-box__input"
                                  v-bind="attrs"
                                  type="text"
                                  :placeholder="$t('input.phone')"
                                  trim
                                  v-on="listeners"
                                />
                              </form-group>
                              <form-group
                                attribute="input.email"
                                name="user_email"
                              >
                                <b-form-input
                                  slot-scope="{ attrs, listeners }"
                                  v-model="form.user_email"
                                  class="form-box__input"
                                  v-bind="attrs"
                                  type="text"
                                  :placeholder="$t('input.email')"
                                  trim
                                  v-on="listeners"
                                />
                              </form-group>
                              <section class="contact-us__select-container">
                                <form-group
                                  attribute="input.topic"
                                  name="topic_id"
                                >
                                  <b-form-select
                                    slot-scope="{ attrs, listeners }"
                                    v-bind="attrs"
                                    v-on="listeners"
                                    v-model.number="form.topic_id"
                                    :placeholder="$t('input.topic')"
                                    :options="topics"
                                    class="contact-us__select"
                                  >
                                  </b-form-select>
                                </form-group>
                                <span
                                  v-if="form.topic_id === ''"
                                  class="contact-us__select--placeholder"
                                  >{{ $t("input.topic") }}</span
                                >
                              </section>
                              <form-group
                                label-for="message"
                                attribute="input.message"
                                name="message"
                              >
                                <b-form-textarea
                                  no-resize
                                  slot-scope="{ attrs, listeners }"
                                  v-model="form.message"
                                  class="form-box__input"
                                  v-bind="attrs"
                                  type="text"
                                  rows="5"
                                  :placeholder="$t('input.message')"
                                  trim
                                  v-on="listeners"
                                />
                              </form-group>
                              <multiImageUpload
                                :maxSize="maxSize"
                                @fileSelected="handleAttachment"
                              />
                              <Button
                                type="submit"
                                class="button button--filled w-100 my-3"
                                :text="$t('button.submit')"
                                :hasIcon="false"
                                :disabled="$v.form.$invalid"
                              />
                            </form-wrapper>
                          </b-col>
                        </b-row>
                      </b-form>
                      <transition v-else name="fadeIn" mode="in-out">
                        <section class="contact-us__success">
                          <h2 class="contact-us__success--heading">
                            {{ $t("label.request_pending") }}
                          </h2>
                          <b-img
                            :src="
                              require('@/assets/imgs/illustrations/contactUs/sucess.svg')
                            "
                            fluid
                          ></b-img>
                          <p class="contact-us__success--text">
                            {{ $t("label.request_success_message") }}
                          </p>
                        </section>
                      </transition>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
            <b-col lg="6">
              <div class="contact-us__right-side">
                <contact-list />
                <socials class="mt-5" />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </section>

    <!-- Start Your Project Section -->
    <project />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { IndexData, StoreData } from "@/helpers/apiMethods";
import {
  required,
  maxLength,
  minLength,
  email,
  numeric
} from "vuelidate/lib/validators";

export default {
  name: "ContactUs",
  components: {
    Project: () => import("@/components/Project"),
    ContactList: () => import("@/components/Contacts/ContactList"),
    Socials: () => import("@/components/Contacts/Socials")
  },
  data() {
    return {
      form: {
        user_name: "",
        user_phone: "",
        user_email: "",
        topic_id: "",
        message: "",
        attachments: []
      },
      maxSize: 5,
      topics: [],
      isSubmited: false,
      contactDescription: ""
    };
  },
  mounted() {
    this.handleGetTopics();
  },
  computed: {
    ...mapGetters(["GlobalSettings", "settings"])
  },
  watch: {
    GlobalSettings: {
      handler() {
        this.contactDescription = this.settings("contact_description");
      },
      immediate: true
    }
  },
  methods: {
    handleAttachment(attachment) {
 
      this.form.attachments = attachment;
    
    },
    async handleGetTopics() {
      const data = await IndexData({ reqName: "topics" });
      const { topics } = data.data;
      this.topics = topics.map(el => {
        return {
          value: el.id,
          text: el.name
        };
      });
    },
    handleSubmit() {
      const formData = new FormData();
      Object.keys(this.form).map(key => {
        if (key !== "attachments") {
          formData.append(key, this.form[key]);
        } else {
          this.form[key].map((attachment, index) =>
            formData.append(`attachments[${index}]`, attachment)
          );
        }
      });

      StoreData({ reqName: "contact-messages", data: formData })
        .then(() => {
          this.isSubmited = true;
          this.analytics.fbq.event("SubmitApplication");
          this.analytics.fbq.event("Contact");

      
        })
        .then(() => {
          setTimeout(() => {
            this.$router.push("/");
          }, 3000);
        })
        .catch(err => console.log(err));
    }
  },
  validations() {
    return {
      form: {
        user_name: {
          required,
          maxLength: maxLength(20)
        },
        user_phone: {
          numeric,
          required,
          maxLength: maxLength(20)
        },
        user_email: {
          required,
          email
        },
        topic_id: {
          required,
          type: String
        },
        message: {
          required,
          minLength: minLength(15),
          maxLength: maxLength(500)
        }
      }
    };
  }
};
</script>
